<script setup>
import { userAuthState } from '@/@core/auth'
import { cookieRef } from '@/@layouts/stores/config'
import { addDays, addMonths, customDateFormat } from "@/@layouts/utils"
import CategoryGraph from '@/views/incident/dashboard/CategoryGraph.vue'
import DegreeOfHarmMonthlyGraph from '@/views/incident/dashboard/DegreeOfHarmMonthlyGraph.vue'
import DepartmentGraph from '@/views/incident/dashboard/DepartmentGraph.vue'
import IncidentTypeGraph from '@/views/incident/dashboard/IncidentTypeGraph.vue'
import OutcomeGraph from '@/views/incident/dashboard/OutcomeGraph.vue'
import { VSkeletonLoader } from 'vuetify/lib/labs/components.mjs'
import ClassificationGraph from '@/views/incident/dashboard/ClassificationGraph.vue'
import IncidentTable from '@/views/incident/incident-panel/IncidentTable.vue'
import RejectionTypeGraph from '@/views/incident/dashboard/RejectionTypeGraph.vue'
import { can } from '@/@layouts/plugins/casl'

definePage({
  meta: {
    name: "incident-dashboard",
    subject: "dashboard.incident",
    action: "read",
  },
})

const auth = userAuthState()

const quickDateRange = ref("Last 30 days")

const quickDateRangeOptions = [
  {
    title: "Today",
    value: `${customDateFormat(new Date())}`,
  },
  {
    title: "Yesterday",
    value: `${customDateFormat(addDays(new Date(), -1))}`,
  },
  {
    title: "Last 7 days",
    value: `${customDateFormat(addDays(new Date(), -6))} to ${customDateFormat(
      new Date(),
    )}`,
  },
  {
    title: "Last 30 days",
    value: `${customDateFormat(addDays(new Date(), -30))} to ${customDateFormat(
      new Date(),
    )}`,
  },
  {
    title: "This month",
    value: `${customDateFormat(startOfMonth(new Date()))} to ${customDateFormat(
      new Date(),
    )}`,
  },
  {
    title: "Last month",
    value: `${customDateFormat(
      startOfMonth(addMonths(new Date(), -1)),
    )} to ${customDateFormat(endOfMonth(addMonths(new Date(), -1)))}`,
  },
  {
    title: "Custom",
    value: null,
  },
]

const setQuickDateRange = ({ value }) => {
  dateRange.value = value
}

const dateRange = ref()
const fromDate = ref(customDateFormat(addDays(new Date(), -30)))
const toDate = ref(customDateFormat(new Date()))

const dateRangeOptions = ref({
  mode: "range",
  maxDate: new Date(),
  dateFormat: "Y-m-d",
  enableTime: false,
  enableSeconds: false,
  noCalendar: false,
  altInput: true,
  altFormat: "F j, Y",
  altInputClass: "form-control",
  altInputPlaceholder: "Select date",
  inline: false,
  static: false,
  appendTo: document.body,
  clickOpens: true,
  defaultDate: [new Date(), new Date()],
  onChange: (selectedDates, dateStr, instance) => {
    fromDate.value = customDateFormat(selectedDates[0])
    if (selectedDates[1]) {
      toDate.value = customDateFormat(selectedDates[1])
    } else {
      toDate.value = customDateFormat(selectedDates[0])
    }
    dateRange.value = dateStr
  },
  defaultDate: [
    customDateFormat(addDays(new Date(), -30)),
    customDateFormat(new Date()),
  ],
})

const dateRangeValue = computed(() => {
  return [
    fromDate.value,
    toDate.value,
  ]
})

const unitId = ref(cookieRef('selectedUnit').value ?? null)

const units = computed(() => auth.user?.units ?? [])

const departmentIds = ref([])

const {
  data: departments,
} = await useApi(createUrl('/masters/departments', {
  query: {
    select: ['id', 'name'],
  },
}))

const {
  isFetching: incidentCountsFetching,
  data: incidentCounts,
  execute: fetchIncidentCounts,
} = useApi(createUrl('/dashboard/incident/counts', {
  query: {
    search: {
      unit_id: unitId,
      created_at: dateRangeValue,
      department_id: departmentIds,
    },
  },
}))


const incidentTableFilter = ref(null)
const isIncidentTableDialogVisible = ref(false)

const onUpdateIncidentTableFilter = data => {
  incidentTableFilter.value = {
    created_at: [fromDate.value, toDate.value],
    unit_id: unitId.value,
    ...data,
  }
  isIncidentTableDialogVisible.value = true
}
</script>

<template>
  <VRow class="mb-4">
    <VCol
      md="12"
      sm="12"
      xs="12"
    >
      <VCard>
        <VCardText>
          <span class="text-h6 font-weight-bold text-primary text-capitalize">
            Dashboard Filter
          </span>
          <VRow align="center">
            <VCol
              cols="12"
              md="3"
              sm="12"
              xs="4"
            >
              <AppSelect
                v-model="quickDateRange"
                label="Quick Range"
                return-object
                :item-title="(item) => item.title"
                :item-value="(item) => item.title"
                :items="quickDateRangeOptions"
                @update:model-value="setQuickDateRange"
              />
            </VCol>
            <VCol
              cols="12"
              md="3"
              sm="12"
              xs="12"
            >
              <AppDateTimePicker
                v-model="dateRange"
                label="Range"
                placeholder="Select date"
                :config="dateRangeOptions"
              />
            </VCol>
            <VCol
              cols="12"
              md="3"
              sm="12"
              xs="12"
            >
              <AppCombobox
                v-model="departmentIds"
                :items="departments ?? []"
                :item-title="(item) => item.name"
                :item-value="(item) => item.id"
                label="Select Department"
                placeholder="Filter By Department"
                :multiple="true"
                :return-object="false"
                :clearable="true"
              />
            </VCol>
            <VCol
              cols="12"
              md="3"
              sm="12"
              xs="12"
            >
              <AppSelect
                v-model="unitId"
                label="Select Unit"
                placeholder="Filter By Unit"
                :items="units ?? []"
                :item-title="(item) => item.name"
                :item-value="(item) => item.id"
              />
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>

  <VRow class="py-6">
    <template v-if="incidentCountsFetching">
      <VCol
        v-for="i in 4"
        :key="i"
        cols="12"
        sm="6"
        md="3"
      >
        <VSkeletonLoader
          class="mx-auto"
          elevation="2"
          width="100%"
          type="sentences"
        />
      </VCol>
    </template>
    <template v-else>
      <VCol
        v-for="(item, index) in incidentCounts"
        :key="index"
        cols="12"
        sm="6"
        md="3"
      >
        <VCard>
          <VCardText>
            <div class="d-flex align-center">
              <VAvatar
                variant="tonal"
                :color="item.color"
                rounded
                size="54"
                class="text-primary me-4"
              >
                <VIcon
                  :icon="item.icon"
                  size="38"
                />
              </VAvatar>
              <div>
                <h6 class="text-h6 text-medium-emphasis">
                  {{ item.title }}
                </h6>
                <div class="d-flex gap-4">
                  <h4
                    class="text-h4"
                    :class="`text-${item.color}`"
                  >
                    {{ item.count }}
                  </h4>
                  <VChip
                    v-if="subValue"
                    class="mt-1"
                    label
                    size="small"
                    :color="isNagativeNumber(subValue) ? 'error' : 'success'"
                  >
                    <VIcon
                      :icon="
                        isNagativeNumber(subValue)
                          ? 'tabler-arrow-down'
                          : 'tabler-arrow-up'
                      "
                      class="mr-1"
                    />
                    {{ subValue }}%
                  </VChip>
                </div>
              </div>
            </div>
          </VCardText>
        </VCard>
      </VCol>
    </template>
  </VRow>

  <VRow
    v-if="can('view', 'dashboard.widget.department_status', 'incident')"
    class="py-2"
  >
    <VCol cols="12">
      <VCard>
        <VCardText>
          <DepartmentGraph
            
            :unit-id="unitId"
            :department-ids="departmentIds"
            :from-date="fromDate"
            :to-date="toDate"
            @update:incident-table-filter="onUpdateIncidentTableFilter"
          />
        </VCardText>
      </VCard>
    </VCol>
  </VRow>

  <VRow
    v-if="can('view', 'dashboard.widget.degree_of_harm', 'incident')"
    class="py-2"
  >
    <VCol cols="12">
      <VCard>
        <VCardText>
          <DegreeOfHarmMonthlyGraph
            
            :unit-id="unitId"
            :department-ids="departmentIds"
            :from-date="fromDate"
            :to-date="toDate"
            @update:incident-table-filter="onUpdateIncidentTableFilter"
          />
        </VCardText>
      </VCard>
    </VCol>
  </VRow>

  <VRow class="py-2">
    <VCol 
      v-if="can('view', 'dashboard.widget.classification', 'incident')"
      cols="12" 
      md="6"
    >
      <VCard>
        <VCardText>
          <ClassificationGraph
            :unit-id="unitId"
            :department-ids="departmentIds"
            :from-date="fromDate"
            :to-date="toDate"
            @update:incident-table-filter="onUpdateIncidentTableFilter"
          />
        </VCardText>
      </VCard>
    </VCol>
    <VCol 
      v-if="can('view', 'dashboard.widget.outcome', 'incident')"
      cols="12" 
      md="6"
    >
      <VCard>
        <VCardText>
          <OutcomeGraph 
            :unit-id="unitId"
            :department-ids="departmentIds"
            :from-date="fromDate"
            :to-date="toDate"
            @update:incident-table-filter="onUpdateIncidentTableFilter"
          />
        </VCardText>
      </VCard>
    </VCol>
  </VRow>

  <VRow
    v-if="can('view', 'dashboard.widget.category', 'incident')"
    class="py-2"
  >
    <VCol cols="12">
      <VCard>
        <VCardText>
          <CategoryGraph 
            :unit-id="unitId"
            :department-ids="departmentIds"
            :from-date="fromDate"
            :to-date="toDate"
            @update:incident-table-filter="onUpdateIncidentTableFilter"
          />
        </VCardText>
      </VCard>
    </VCol>
  </VRow>

  <VRow class="py-2">
    <VCol 
      v-if="can('view', 'dashboard.widget.incident_type', 'incident')"
      cols="12"
      md="6"
    >
      <VCard>
        <VCardText>
          <IncidentTypeGraph 
            :unit-id="unitId"
            :department-ids="departmentIds"
            :from-date="fromDate"
            :to-date="toDate"
            @update:incident-table-filter="onUpdateIncidentTableFilter"
          />
        </VCardText>
      </VCard>
    </VCol>
    <VCol 
      v-if="can('view', 'dashboard.widget.rejection_type', 'incident')"
      cols="12"
      md="6"
    >
      <VCard>
        <VCardText>
          <RejectionTypeGraph
            :unit-id="unitId"
            :department-ids="departmentIds"
            :from-date="fromDate"
            :to-date="toDate"
            @update:incident-table-filter="onUpdateIncidentTableFilter"
          />
        </VCardText>
      </VCard>
    </VCol>
  </VRow>

  <!-- Incident Table Dialog -->
  <VDialog v-model="isIncidentTableDialogVisible">
    <!-- 👉 dialog close btn -->
    <DialogCloseBtn
      size="small"
      @click="() => (isIncidentTableDialogVisible = !isIncidentTableDialogVisible)"
    />

    <VCard>
      <VCardText>
        <IncidentTable
          ref="refTable"
          :filters="incidentTableFilter"
          :config="{
            archiveList: false,
            archiveVisible: false,
            createActionButtonVisible: false,
            tableActionColumnVisible: false,
          }"
        />
      </VCardText>
    </VCard>
  </VDialog>
</template>
